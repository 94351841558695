<template>
  <div class="p-4 bg-white rounded-lg">
    <div class="custom-content-height">
      <div class="row mx-1">
        <div class="card-header w-100 border-bottom-0 px-2">
          <datatable-detail
            class="col-12 col-sm-12 col-md-12"
            :table_properties="table_properties"
          ></datatable-detail>
        </div>
      </div>
      <ReportsChartSelector :item="item_data" :filter-data="filters_for_charts">
      </ReportsChartSelector>
    </div>
  </div>
</template>

<script>
import ReportsChartSelector from "@/own/components/reports/ReportsChartSelector";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "reportPortalUsersTime",
  components: {
    ReportsChartSelector,
    DatatableDetail,
  },
  data: () => ({
    serverData: null,
    item_data: {
      url: "/reports/reportPortalUsageTimeHeatmap/show",
    },
    filters_for_charts: [
      {
        name: "client",
        value: [9],
      },
      {
        name: "minimumOrderCount",
        value: null,
      },
      {
        name: "date_range",
        value: ["2021-07-01", "2021-12-31"],
      },
    ],
    table_properties: {
      title: "Portal Usage Heatmap",
      description: "Report",
    },
  }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, false);
  },
};
</script>

<style></style>
